import React, { useState, useCallback, useRef, useEffect } from "react";
import autosize from "autosize";
import ChatFormatted from "./chatFormatted.jsx";
import io from "socket.io-client";
import NewChat from "../NewChat/newChat.jsx";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ExtensionIcon from "@mui/icons-material/Extension";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SettingsIcon from "@mui/icons-material/Settings";
import FileContainer from "./components/file/fileContainer.jsx";
import * as pdfjs from "pdfjs-dist";
import { pdfjsWorker } from "pdfjs-dist/webpack";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { extractTextFromPDF } from "./pdf/pdfExtract.jsx";
import pako from "pako";
import { toast } from "react-toastify";
import Cookies from 'js-cookie';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const Chat = ({
  Request,
  selectedChatRef,
  updateChatDb,
  darkMode,
  ...props
}) => {
  const {
    selectedChat,
    decryptedToken,
    setChats,
    setSelectedChat,
    messages,
    setMessages,
    user,
    setSettings,
    plugin,
    close,
    setClose,
    handleSettings,
    navigateToDashboard,
    progress,
    setProgress,
    socket,
    setGenerationTaskId,
    handleStopGeneration,
    selectedAgentRef,
    files,
    setFiles,
    setShowPdfViewer,
    setPdfFile,
    setPdfPage,
    setHighlightText
  } = props;

  // const [generationTaskId, setGenerationTaskId] = useState(null);
  const [sendReady, setSendReady] = useState(false);

  const [messageText, setMessageText] = useState("");
  const [selectedRole, setSelectedRole] = useState("user");
  const [temperature] = useState(1);
  const [hideProgressBar, setHideProgressBar] = useState(false);
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [hoveredMessageIndex, setHoveredMessageIndex] = useState(null);
  const [maxTokens] = useState(2048);
  const [template, setTemplate] = useState("");
  const [parameters, setParameters] = useState([]);
  const [showInput, setShowInput] = useState(false);

  const [closeButtons, setCloseButtons] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  // const apiUrl = localStorage.getItem("apiUrl");
  const apiUrl = Cookies.get('apiUrl');

  // const socket = useRef(null);
  const textareaRef = useRef(null);
  const messagesRef = useRef(messages);
  const parametersRef = useRef(parameters);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  // const handleStopGeneration = useCallback(() => {
  //   if (generationTaskId && socket.current) {
  //     socket.current.emit("stopGeneration", {
  //       taskId: generationTaskId,
  //       chatId: selectedChatRef.current.id,
  //       chatName: selectedChatRef.current.name,
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [generationTaskId]);

  async function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const fetchData = async () => {
      let res = await Request.Get("protected/openai/models");
      setModels(res.models);
      setSelectedModel(res.models[0]);
    };

    fetchData();
    setSendReady(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    messagesRef.current = messages;
    if (messagesRef.current.length > 1) setHideProgressBar(true);
    // eslint-disable-next-line
  }, [messages]);

  useEffect(() => {
    if (
      messages.length === 1 &&
      messages[0].role === "function" &&
      messages[0].template.function
    ) {
      setShowInput(false);
    } else if (
      messages[0] &&
      messages[0].functionName &&
      messages[0].functionName === "DCE"
    ) {
      setShowInput(false);
    } else {
      scrollToBottom();
      setShowInput(true);
    }
  }, [messages]);

  useEffect(() => {
    if (selectedChat.id && messages.length > 0 && !closeButtons)
      updateChatDb({ id: selectedChat.id, messages });
    // eslint-disable-next-line
  }, [messages]);

  useEffect(() => {
    parametersRef.current = parameters;
  }, [parameters]);

  useEffect(() => {
    setHideProgressBar(false);
    handleStopGeneration();
    //eslint-disable-next-line
  }, [selectedChat]);

  useEffect(() => {
    if (!decryptedToken) {
      return;
    }

    socket.current = io(`${apiUrl}`, {
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      randomizationFactor: 0.5,
    });

    socket.current.on("connect", () => {});

    let receivedMessage = "";

    socket.current.on("chatResponse", async (response) => {
      if (response && !response.end && response.content) {
        receivedMessage += response.content;
        if (!response.end) {
          if (response.progress) setProgress(response.progress);
          setMessages((prevMessages) => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            const updatedMessage = {
              ...lastMessage,
              title: response.title ? response.title : null,
              description: response.description ? response.description : null,
              filePath: response.filePath ? response.filePath : null,
              structure: response.structure ? response.structure : null,
              function: response.function ? response.function : null,
              functionName: response.functionName
                ? response.functionName
                : null,
              contentToShow: response.contentToShow
                ? response.contentToShow
                : null,
              content:
                response.type === "json" || response.functionName === "DCE" || response.functionName === "ACCEO"
                  ? response.content
                  : receivedMessage,
              type: response.type ? response.type : null,
              image: response.image ? response.image : null,
              alt: response.alt ? response.alt : null,
              edit: response.edit ? response.edit : null,
              variations: response.variations ? response.variations : null,
            };
            return prevMessages
              .slice(0, prevMessages.length - 1)
              .concat([updatedMessage]);
          });
          setMessages((prevMessages) => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            const updatedMessage = {
              ...lastMessage,
              type: response.type ? response.type : null,
            };
            return prevMessages
              .slice(0, prevMessages.length - 1)
              .concat([updatedMessage]);
          });
        }
        return;
      } else if (response && response.end) {
        if (response.status && response.status === 400) {
          toast.error(response.message);
          if (!receivedMessage || receivedMessage === "") {
            setMessages((prevMessages) => prevMessages.slice(0, -1));
          }
        }
        if (selectedChatRef.current.id && messages.length > 0) {
          await sleep(500);
          try {
            await updateChatDb({
              id: selectedChatRef.current.id,
              files: files,
              messages:
                messagesRef.current[0]?.role === "function"
                  ? messagesRef.current.slice(1)
                  : messagesRef.current,
            });
          } catch (error) {
            console.log(error);
          }
          receivedMessage = "";
          setTemplate("");
          setParameters([]);
          setSendReady(true);
        }
        setSendReady(true);
      } else if (response && response.title) {
        setChats((prevChats) => [
          ...prevChats,
          { id: response.chatId, name: response.chatName, favorite: false },
        ]);
        await sleep(500);
        try {
          await updateChatDb({
            id: response.chatId,
            files: files,
            messages:
              messagesRef.current[0]?.role === "function"
                ? messagesRef.current.slice(1)
                : messagesRef.current,
          });
        } catch (error) {
          console.log(error);
        }
        await setSelectedChat({
          id: response.chatId,
          name: response.chatName,
          favorite: false,
        });
        receivedMessage = "";
        setTemplate("");
        setParameters([]);
        setSendReady(true);
      }
    });

    socket.current.on("taskId", (taskId) => {
      setGenerationTaskId(taskId);
    });

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
    // eslint-disable-next-line
  }, [selectedChat]);

  const addMessage = useCallback((newMessage) => {
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    // eslint-disable-next-line
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  async function uploadFile(file) {
    const formData = new FormData();
    formData.append("fileField", file);

    const response = await Request.PostFile(
      "/protected/uploads",
      formData,
      file.name,
      null,
      null,
      Cookies.get("websiteToken")
    );

    setFiles((prevFiles) => [
      ...prevFiles,
      {
        type: response.fileName.split(".").pop().toLowerCase(),
        name: response.fileName,
        size: response.size,
      },
    ]);

    return response;
  }

  const addFile = useCallback(async (event) => {
    try {
      const file = event.target.files[0];
      await uploadFile(file);
    } catch (error) {
      console.log("error:", error);
    }
  }, []);

  const handleSendMessage = useCallback(
    (event, text = messageText, isFromFunction = false) => {
      event.preventDefault();

      let updatedMessagesForSending = null;

      if (textareaRef.current) {
        textareaRef.current.style.height = "30px";
      }

      if (!isFromFunction) {
        const newMessageObj = {
          role: selectedRole,
          content: text,
        };

        const updatedMessages = [...messages, newMessageObj];
        updatedMessagesForSending = updatedMessages.map(
          ({ role, content }) => ({ role, content })
        );

        setMessages(updatedMessages);
      }

      if (selectedRole !== "user") {
        setSendReady(true);
        setMessageText("");
        return;
      } else {
        let updatedParameters;
        setParameters((prevParameters) => {
          updatedParameters = [...prevParameters];
          return updatedParameters;
        });
      }

      addMessage({ role: "assistant", content: "" });

      if (template.label === "DCE") setHideProgressBar(true);

      const obj = {
        scope: "intern",
        chatId: selectedChat.id,
        chatName: selectedChat.name,
        model: selectedModel,
        temperature,
        maxTokens,
        messages: updatedMessagesForSending
          ? updatedMessagesForSending
          : messages,
        decryptedToken,
        role: selectedRole,
        plugin: plugin === "cogichat" ? "cogipro" : "onboarding",
        template: plugin === "cogichat" ? template : null,
        function:
          plugin === "cogichat" && template.function
            ? template.function
            : false,
        parameters: parametersRef.current,
        selectedAgentRef: selectedAgentRef.current
          ? selectedAgentRef.current
          : null,
        files: files,
      };

      const jsonString = JSON.stringify(obj);
      const compressedData = pako.gzip(jsonString);

      socket.current.emit("chat", compressedData, (error) => {
        if (error) {
          console.error(error);
          return;
        }
      });

      setMessageText("");
    },
    // eslint-disable-next-line
    [
      selectedRole,
      messageText,
      selectedModel,
      temperature,
      decryptedToken,
      messages,
      addMessage,
      maxTokens,
    ]
  );

  const removeFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleInputChange = useCallback((event) => {
    setMessageText(event.target.value);
    const textarea = event.target;
    textarea.style.height = "auto";
    const maxHeightVh = window.innerHeight * 0.6;
    textarea.style.height = `${Math.min(textarea.scrollHeight, maxHeightVh)}px`;
  }, []);

  const handleRoleChange = useCallback((event) => {
    setSelectedRole(event.target.value);
  }, []);

  const handleModelChange = useCallback(
    (event) => {
      const modelIndex = event.target.value;
      const selectedModelObject = models[modelIndex];
      setSelectedModel(selectedModelObject);
    },
    [models]
  );

  const handleKeyDown = (event) => {
    if (sendReady && event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (messageText.trim() === "") {
        toast.error("The message cannot be empty.");
        return;
      }
      setSendReady(false);
      handleSendMessage(event);
    } else {
      return;
    }
  };

  const instantUpdateChatDb = async () => {
    updateChatDb({ id: selectedChat.id, messages });
  };

  const handleSendIconClick = (event) => {
    if (sendReady) {
      setSendReady(false);
      handleSendMessage(event);
    }
  };

  const handleMouseEnter = (index) => {
    setHoveredMessageIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredMessageIndex(null);
  };

  const handleDeleteMessage = (indexToDelete) => {
    setMessages((prevMessages) => {
      const updatedMessages = prevMessages.filter(
        (_, index) => index !== indexToDelete
      );
      updateChatDb({ id: selectedChat.id, messages: updatedMessages });
      return updatedMessages;
    });
  };

  const handleNewChat = () => {
    setSelectedChat({ name: null, id: null, favorite: false });
    setMessages([]);
  };

  const handleSettingsClick = () => {
    setShowButtons(!showButtons);
    setCloseButtons(!closeButtons);
  };

  const onFileChange = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              role: "system",
              name: file.name,
              content: "Processing...",
              type: "progress",
              progress: 0,
            },
          ]);
          const progressCallback = (progress) => {
            setMessages((prevMessages) => {
              const messagesCopy = [...prevMessages];
              const lastMessage = messagesCopy[messagesCopy.length - 1];
              if (lastMessage.type === "progress") {
                lastMessage.content = `Processing... (${Math.round(
                  progress * 100
                )}%)`;
                lastMessage.progress = progress;
              }
              return messagesCopy;
            });
          };
          const pdf = await extractTextFromPDF(
            new Uint8Array(e.target.result),
            file,
            progressCallback
          );
          setMessages((prevMessages) => {
            const messagesCopy = [...prevMessages];
            const lastMessage = messagesCopy[messagesCopy.length - 1];
            if (lastMessage.type === "progress") {
              lastMessage.pages = pdf.text;
              lastMessage.content = "pdf";
              lastMessage.type = "pdf";
              delete lastMessage.progress;
            }
            return messagesCopy;
          });
        } catch (error) {
          console.error("Error extracting text from PDF:", error);
        }
      };
      reader.readAsArrayBuffer(file);
    }
    // eslint-disable-next-line
  }, []);

  const updateMessage = (index, updatedMessage) => {
    setMessages(
      messages.map((message, idx) => (idx === index ? updatedMessage : message))
    );
  };

  const updateMessageContent = (index, newContent) => {
    setMessages((currentMessages) =>
      currentMessages.map((msg, idx) =>
        idx === index ? { ...msg, content: newContent } : msg
      )
    );
  };

  const sharedChat = async () => {
    let res = await Request.Post(
      "/protected/shared",
      selectedChatRef.current.id
    );
    if (res && res.id) {
      const newTabUrl = `${window.location.origin}/shared/${res.id}`;
      window.open(newTabUrl, "_blank");
    }
  };

  return (
    <div className="chat">
      <div className="chat-messages">
        {messages.length > 0 ? (
          messages.map((messageObj, index) => (
            <div
              key={index}
              className={`message ${index % 2 === 0 ? "pair" : "unpair"}`}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <ChatFormatted
                index={index}
                handleSendMessage={handleSendMessage}
                project={template}
                message={messageObj}
                setSendReady={setSendReady}
                darkMode={darkMode}
                editable={true}
                user={user}
                progress={progress}
                parameters={parameters}
                setParameters={setParameters}
                updateMessage={(newMessage) => updateMessage(index, newMessage)}
                hideProgressBar={hideProgressBar}
                Request={Request}
                updateMessageContent={updateMessageContent}
                selectedChat={selectedChat}
                updateChatDb={updateChatDb}
                sharedChat={sharedChat}
                shared={false}
                setShowPdfViewer={setShowPdfViewer}
                setPdfFile={setPdfFile}
                setPdfPage={setPdfPage}
                setHighlightText={setHighlightText}
                template={template}
                setShowInput={setShowInput}
              />
              {hoveredMessageIndex === index && (
                <div className="message-actions">
                  <div className="btn">
                    <svg
                      onClick={() => handleDeleteMessage(index)}
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="trash h-4 w-4"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polyline points="3 6 5 6 21 6"></polyline>
                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                      <line x1="10" y1="11" x2="10" y2="17"></line>
                      <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <NewChat
            plugin={plugin}
            setMessages={setMessages}
            template={template}
            setTemplate={setTemplate}
            setSettings={setSettings}
            user={user}
            setSendReady={setSendReady}
            handleSendMessage={handleSendMessage}
          />
        )}
        <div className="down" ref={messagesEndRef} />
      </div>

      {showInput && (
        <div className="chat-input">
          <div className="input-background"></div>
          <div className="input-container">

            {sendReady && (
              <div className="files">
                <div className="addFile" onClick={triggerFileInput}>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={addFile}
                    accept=".pdf,.doc,.docx"
                  />
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9 7C9 4.23858 11.2386 2 14 2C16.7614 2 19 4.23858 19 7V15C19 18.866 15.866 22 12 22C8.13401 22 5 18.866 5 15V9C5 8.44772 5.44772 8 6 8C6.55228 8 7 8.44772 7 9V15C7 17.7614 9.23858 20 12 20C14.7614 20 17 17.7614 17 15V7C17 5.34315 15.6569 4 14 4C12.3431 4 11 5.34315 11 7V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V9C13 8.44772 13.4477 8 14 8C14.5523 8 15 8.44772 15 9V15C15 16.6569 13.6569 18 12 18C10.3431 18 9 16.6569 9 15V7Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                {files.length > 0 &&
                  files.map((file, index) => (
                    <FileContainer
                      index={index}
                      fileName={file.name}
                      fileSize={file.size}
                      type={file.type}
                      removeFile={removeFile}
                    />
                  ))}
              </div>
            )}
            <div className="options">
              {models.length > 0 && sendReady && (
                <>
                  <select
                    className="role"
                    value={selectedRole}
                    onChange={handleRoleChange}
                  >
                    <option value="system">Système</option>
                    <option value="user">Utilisateur</option>
                    <option value="assistant">Assistant</option>
                  </select>
                  <select
                    className="model"
                    value={models.findIndex((model) => model === selectedModel)}
                    onChange={handleModelChange}
                  >
                    {models.map((model, index) => (
                      <option value={index} key={index}>
                        {`${model.Provider} - ${model.label}`}
                      </option>
                    ))}
                  </select>
                  <button
                    disabled={messages.length === 0}
                    onClick={() => {
                      sharedChat();
                    }}
                    type="button"
                    className="bounce text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon-sm"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
                      <polyline points="16 6 12 2 8 6"></polyline>
                      <line x1="12" y1="2" x2="12" y2="15"></line>
                    </svg>
                    Partager
                  </button>
                </>
              )}
              {!sendReady && (
                <button type="button" onClick={handleStopGeneration}>
                  Stop
                </button>
              )}
            </div>
            <form
              className="input"
              onSubmit={(event) => {
                event.preventDefault();
                handleSendMessage(event);
              }}
            >
              <div className="textarea-container">
                <textarea
                  ref={textareaRef}
                  type="text"
                  placeholder="Entrez votre message ici..."
                  value={messageText}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
                {sendReady ? (
                  <svg
                    onClick={handleSendIconClick}
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="h-4 w-4 mr-1"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line x1="22" y1="2" x2="11" y2="13"></line>
                    <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <circle cx="6" cy="12" r="2" fill="currentColor" />
                    <circle cx="12" cy="12" r="2" fill="currentColor" />
                    <circle cx="18" cy="12" r="2" fill="currentColor" />
                  </svg>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;
