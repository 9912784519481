import React, { useCallback } from "react";

import Div100vh from "react-div-100vh";

import useRequest from "../../../useRequest";
import Cookies from 'js-cookie';

const FileUpload = () => {
  const Request = useRequest();

  // async function uploadPdf(file) {
  //   const formData = new FormData();
  //   formData.append("fileField", file);
  //   formData.append("chunkIndex", 0);
  //   formData.append("isLastChunk", true);

  //   console.log("formData:", formData);

  //   const headers = {
  //     "file-name": file.name,
  //     "chunk-index": 0,
  //     "is-last-chunk": true,
  //   };

  //   console.log("headers:", headers);

  //   let reqObj = {
  //     method: "POST",
  //     headers: headers,
  //     body: formData,
  //   };

  //   console.log("reqObj:", reqObj);

  //   const response = await fetch(
  //     "https://cyberr-api.aitwork.io/public/uploads",
  //     reqObj
  //   );

  //   console.log("response:", response);

  //   const data = await response.json(); // ou await response.text() si la réponse est une chaîne de caractères

  //   console.log("data:", data);
  //   return data;
  // }

  async function uploadFile(file) {
    const formData = new FormData();
    formData.append("fileField", file);

    const response = await Request.PostFile(
      "/extern/uploads",
      formData,
      file.name,
      null,
      null,
      Cookies.get('websiteToken')
    );

    console.log("response:", response);

    return response;
  }

  const onFileChange = useCallback(async (event) => {
    try {
      const pdf = event.target.files[0];
      await uploadFile(pdf);
    } catch (error) {
      console.log("error:", error);
    }
  }, []);

  return (
    <Div100vh className="dashboard">
      <>
        <input
          id="file-upload"
          type="file"
          accept=".pdf,.docx,.zip"
          onChange={onFileChange}
        />
      </>
    </Div100vh>
  );
};

export default FileUpload;
