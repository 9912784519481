import React, { useEffect, useRef, useState } from "react";
import Editor, { useMonaco } from "@monaco-editor/react";
import io from "socket.io-client";
import pako from "pako";
import Cookies from 'js-cookie';

function CodeEditor({ darkMode, Request, socket }) {
  const monaco = useMonaco();
  const editorRef = useRef(null);
  const socketRef = useRef(null);
  const [editorHeight, setEditorHeight] = useState("400px");
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [consoleMessages, setConsoleMessages] = useState([]);
  const defaultCode = `
  async function main() {
    console.log("Hello, Monaco Editor!");
    await new Promise((resolve) => setTimeout(resolve, 1000));
    console.log("Delayed log");
  }`;
  // const apiUrl = localStorage.getItem("apiUrl");
  const apiUrl = Cookies.get('apiUrl');

  const adjustEditorHeight = () => {
    const newWindowHeight = window.innerHeight;
    if (newWindowHeight !== windowHeight) {
      const topBarHeight = document.querySelector(".top-bar").offsetHeight;
      const consoleHeight = document.querySelector(".console").offsetHeight;
      const availableHeight = newWindowHeight - topBarHeight - consoleHeight;
      setEditorHeight(`${availableHeight}px`);
      setWindowHeight(newWindowHeight);
    }
  };

  const getCurrentTimeFormatted = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`; // Format %HH:%MM:%SS
  };

  useEffect(() => {
    adjustEditorHeight();
    const topBarHeight = document.querySelector(".top-bar").offsetHeight;
    const consoleHeight = document.querySelector(".console").offsetHeight;
    const availableHeight = windowHeight - topBarHeight - consoleHeight;
    setEditorHeight(`${availableHeight}px`);
    window.addEventListener("resize", adjustEditorHeight);

    if (monaco) {

      const libSource = `
        declare module "aitwork" {
          export function checkLicense(licenseKey: string): returnType;
          export function checkOffer(licenseKey: string): returnType;
          // Ajoutez d'autres déclarations de fonction ici
      }
      `;
      const libUri = 'ts:filename/definitions.d.ts';
      monaco.languages.typescript.javascriptDefaults.addExtraLib(libSource, libUri);

      monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
        target: monaco.languages.typescript.ScriptTarget.ES2015,
        allowNonTsExtensions: true,
      });

      monaco.editor.defineTheme("dracula", {
        base: "vs-dark",
        inherit: true,
        rules: [
          { token: "", foreground: "F8F8F2", background: "282A36" },
          { token: "comment", foreground: "6272A4", fontStyle: "italic" },
          { token: "keyword", foreground: "FF79C6" },
          { token: "number", foreground: "BD93F9" },
          { token: "string", foreground: "F1FA8C" },
        ],
        colors: {
          "editor.background": "#282A36",
          "editor.foreground": "#F8F8F2",
          "editorCursor.foreground": "#F8F8F0",
          "editor.lineHighlightBackground": "#44475A",
          "editorLineNumber.foreground": "#6272A4",
          "editor.selectionBackground": "#44475A",
          "editor.inactiveSelectionBackground": "#44475A",
        },
      });

      monaco.editor.setTheme("dracula");
    }

    socketRef.current = io(apiUrl, {
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      randomizationFactor: 0.5,
    });

    socketRef.current.on("connect", () => {
      console.log("Connected to socket");
    });

    socketRef.current.on("codeResponse", async (response) => {
      console.log(response);
      if (!response.final) {
        setConsoleMessages((prevMessages) => [...prevMessages, {
          time : getCurrentTimeFormatted(), 
          output : response.output
        }]);
      }
    });

    // Nettoyage
    return () => {
      window.removeEventListener("resize", adjustEditorHeight);
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [monaco, apiUrl]);

  const handleEditorDidMount = (editor) => {
    editorRef.current = editor; // Stocke l'instance de l'éditeur dans la référence
  };

  const testCode = async () => {
    setConsoleMessages([]);

    let obj = {
      code: editorRef.current.getValue(),
      decryptedToken: Cookies.get("websiteToken"),
    };

    const jsonString = JSON.stringify(obj);
    const compressedData = pako.gzip(jsonString);

    socketRef.current.emit("code", compressedData, (error) => {
      if (error) {
        console.error(error);
      }
    });
  };

  socket.current.on("codeResponse", async (response) => {
    console.log(response);
  });

  return (
    <div className="container">
      <div className="top-bar">
        <button onClick={() => testCode()}>Exécuter</button>
      </div>
      <div className="editor">
        <Editor
          height={editorHeight}
          defaultLanguage="javascript"
          defaultValue={defaultCode}
          theme="dracula"
          onMount={handleEditorDidMount}
        />
      </div>
      <div className="console">
        {consoleMessages.map((msg, index) => (
          <div className="logline" key={index}>
            <div className="hour">{msg.time}</div>
            <div className="log">{msg.output}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CodeEditor;
