import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NewUser = ({ Request, handleState, company, teams, setUsers }) => {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [teamName, setTeamName] = useState("");
  const [isNewTeam, setIsNewTeam] = useState(false); // Pour gérer la création d'une nouvelle équipe

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let obj = {
        email,
        role,
        isNewTeam,
        teamName,
        company: company._id,
      };

      console.log("obj", obj);

      let res = await Request.Post("/protected/user/new", obj);
      
      if (res.status >= 400) {
        toast.error(res.message || "An error occured.");
      } else {
        setUsers((prevUsers) => [...prevUsers, res]);
        setEmail("");
        setIsNewTeam(false);
        toast.success("Congratulations ! An invitation link has been sent to the user.");
      }
    } catch (error) {
      toast.error(error.message || "An error occured.");
    }
  };

  const teamSuggestions = [
    "Digital Marketing",
    "Sales Operations",
    "Product Marketing",
    "Customer Success",
    "Market Research",
    "Human Resources (HR)",
    "Talent Acquisition",
    "Employee Relations",
    "Learning and Development",
    "HR Compliance",
    "Diversity and Inclusion",
    "Operations",
    "Supply Chain Management",
    "Manufacturing",
    "Quality Assurance",
    "Logistics & Distribution",
    "Procurement",
    "Finance & Administration",
    "Financial Planning & Analysis (FP&A)",
    "Accounting",
    "Treasury",
    "Corporate Finance",
    "Office Administration",
  ];

  useEffect(() => {
    setTeamName("");
  }, [role]);

  const doubledSuggestions = [...teamSuggestions, ...teamSuggestions];

  return (
    <div className="submodule">
      <div className="back" onClick={() => handleState("back", null)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="19" y1="12" x2="5" y2="12"></line>
          <polyline points="12 19 5 12 12 5"></polyline>
        </svg>
      </div>
      <div className="content">
        <h2>Create a new user</h2>

        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email || ""}
            onChange={(event) => setEmail(event.target.value)}
          />
          <select
            value={role}
            onChange={(event) => {
              setRole(event.target.value);
              setIsNewTeam(false); // Réinitialisez lors du changement de rôle
            }}
          >
            <option value="">Select a role</option>
            <option value="superadmin">Super Admin</option>
            <option value="admin">Admin</option>
            <option value="user">User</option>
          </select>
          {role === "admin" ? (
            <>
              <div className="doubleButton">
                <button
                  type="button"
                  onClick={() => {
                    setIsNewTeam(false);
                  }}
                  className={!isNewTeam ? "active" : "inactive"}
                >
                  Existing Team
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setIsNewTeam(true);
                  }}
                  className={isNewTeam ? "active" : "inactive"}
                >
                  New Team
                </button>
              </div>

              {isNewTeam ? (
                <>
                  <h3>Suggestions</h3>

                  <div className="suggestions">
                    <div className="suggestions-container">
                      {doubledSuggestions.map((suggestion, index) => (
                        <div
                          key={index}
                          className="suggestion"
                          onClick={() => setTeamName(suggestion)}
                        >
                          {suggestion}
                        </div>
                      ))}
                    </div>
                  </div>

                  <input
                    type="text"
                    placeholder="New Team Name"
                    value={teamName}
                    onChange={(event) => setTeamName(event.target.value)}
                  />
                </>
              ) : (
                <select
                  value={teamName}
                  onChange={(event) => setTeamName(event.target.value)}
                >
                  <option value="">Select a team</option>
                  {teams.map((division, index) => (
                    <option key={index} value={division.name}>
                      {division.name}
                    </option>
                  ))}
                </select>
              )}
            </>
          ) : role === "user" ? (
            <select
            value={teamName}
            onChange={(event) => setTeamName(event.target.value)}
          >
            <option value="">Select a team</option>
            {teams.map((division, index) => (
              <option key={index} value={division.name}>
                {division.name}
              </option>
            ))}
          </select>
          ) : null}

          <button
            type="submit"
            disabled={
              !email || !role || (role !== "superadmin" && teamName === "")
            }
          >
            Créer
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewUser;
