import React, { useEffect, useRef, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { searchPlugin } from "@react-pdf-viewer/search";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import * as pdfjs from "pdfjs-dist/build/pdf";
import "pdfjs-dist/build/pdf.worker.entry";
import "./PDFViewer.scss";

const PDFViewer = ({
  fileUrl,
  pageNumber,
  highlightText,
  setShowPdfViewer,
  darkMode
}) => {
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const toolbarPluginInstance = toolbarPlugin();
  const searchPluginInstance = searchPlugin();

  const { highlight } = searchPluginInstance;
  const { jumpToPage } = pageNavigationPluginInstance;
  const { Toolbar } = toolbarPluginInstance;
  
  const viewerRef = useRef(null);
  const [isRendered, setIsRendered] = useState(false);

  const splitTextIntoChunks = (text, chunkSize) => {
    const words = text.split(/\s+/); // Diviser en mots par les espaces
    const chunks = [];
    for (let i = 0; i < words.length; i += chunkSize) {
      chunks.push(words.slice(i, i + chunkSize).join(' '));
    }
    return chunks;
  };

  useEffect(() => {
    if (isRendered) {
      setIsRendered(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUrl, pageNumber, highlightText]);

  useEffect(() => {
      const navigateAndHighlight = async () => {

        try {
          if (highlight && highlightText) {
            // Nettoyer le texte à surligner
            const cleanHighlightText = highlightText
              .replace(/^[•\n\s.]+/, '') // Retirer les caractères indésirables au début
              .replace(/\s+/g, ' ')      // Remplacer les multiples espaces par un seul espace
              .replace(/\n/g, ' ')      // Remplacer les nouvelles lignes par des espaces
              .trim();
                
            // Diviser le texte nettoyé en morceaux de trois mots
            const chunks = splitTextIntoChunks(cleanHighlightText, 4);
  
            // Surligner les morceaux
            await highlight(chunks);
          } 
        } catch (error) {
          console.log('error:', error)
        }

        try {
          if (jumpToPage && pageNumber) {
            jumpToPage(pageNumber - 1);
          }
        } catch (error) {
          console.log('error:', error)
        }
      };

    if (isRendered) setTimeout(
      () => {
        navigateAndHighlight();
      },
      200
    );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRendered]);

  const handleDocumentLoad = () => {
    if (!isRendered) setIsRendered(true);
  };

  const handleClose = () => {
    setShowPdfViewer(false);
  };

  return (
    <div className="pdf-viewer-wrapper">
      <Worker
        workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`}
      >
        <div
          className="pdf-viewer-container"
          style={{ height: "100vh", display: "flex", flexDirection: "column" }}
        >
          <div
            className={`toolbar-container ${darkMode ? "toolbar-dark-mode" : "toolbar-light-mode"}`}
            style={{
              flex: "0 1 auto",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0.5rem",
            }}
          >
            <Toolbar />
            <button
              onClick={handleClose}
              style={{
                padding: "0.5rem",
                cursor: "pointer",
                background: "none",
                border: "none",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>{" "}
          </div>
          <div
            style={{
              flex: "1 1 auto",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Viewer
              ref={viewerRef}
              fileUrl={fileUrl}
              onDocumentLoad={handleDocumentLoad}
              theme={darkMode ? "dark" : "light"}
              plugins={[
                pageNavigationPluginInstance,
                searchPluginInstance,
                toolbarPluginInstance,
              ]}
            />
          </div>
        </div>
      </Worker>
    </div>
  );
};

export default PDFViewer;
