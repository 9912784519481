import React, { useState, useRef, useCallback, useEffect } from "react";
import ConfirmationModal from "./confirmationModal";
import Cookies from "js-cookie";
import Checklist from "./CheckList";
import PlurialTextInput from "./PlurialTextInput";
import FileUpload from "./fileUpload";

const Parameters = ({
  param,
  index,
  setParameters,
  setParamStatuses,
  parameters,
  Request,
  template,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [checklistActiveButton, setChecklistActiveButton] = useState(null);
  const [textInputs, setTextInputs] = useState(param.value);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isToggleModalOpen, setIsToggleModalOpen] = useState(false);
  const [currentToggleIdx, setCurrentToggleIdx] = useState(null);
  const [toggleDirection, setToggleDirection] = useState(false);
  const [initialToggleState, setInitialToggleState] = useState(null);
  const [deleteIdx, setDeleteIdx] = useState(null);
  const inputFileRef = useRef(null);
  const parametersRef = useRef([]);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    if (param.type.includes("checklist") || param.type.includes("plurial-text-inputs")) {
      if (parameters[index]?.content) {
        setParamStatuses((prevStatuses) => {
          const newStatuses = [...prevStatuses];
          newStatuses[index] = true;
          return newStatuses;
        });
      } else {
        setParamStatuses((prevStatuses) => {
          const newStatuses = [...prevStatuses];
          newStatuses[index] = false;
          return newStatuses;
        });
      }
    }
    //eslint-disable-next-line
  }, [parameters]);

  useEffect(() => {
    setParameters((prevParameters) => {
      const newParameters = [...prevParameters];
      newParameters[index] = {
        label: param.label,
        type: param.type,
        content: selectedItems,
      };
      return newParameters;
    });
    //eslint-disable-next-line
  }, [selectedItems]);

  useEffect(() => {
    setParameters((prevParameters) => {
      const newParameters = [...prevParameters];
      newParameters[index] = {
        label: param.label,
        type: param.type,
        content: textInputs,
      };
      return newParameters;
  });
  //eslint-disable-next-line
  }, [textInputs]); 

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("fileField", file);

    setIsUploading(true);

    const response = await Request.PostFile(
      "/protected/uploads",
      formData,
      file.name,
      param.minioTags,
      (progress) => setUploadProgress(progress),
      Cookies.get("websiteToken")
    );

    setIsUploading(false);
    setUploadProgress(0);

    console.log("response:", response);

    return response;
  };

  const onFileChange = useCallback(async (event) => {
    try {
      const data = await uploadFile(event.target.files[0]);

      setFileName(data.fileName);
      setFileSize(data.size);

      setParameters((prevParameters) => {
        const newParameters = [...prevParameters];
        newParameters[index] = {
          label: param.label,
          type: param.type,
          fileName: data.fileName,
        };
        parametersRef.current = newParameters;
        return newParameters;
      });
      setParamStatuses((prevStatuses) => {
        const newStatuses = [...prevStatuses];
        newStatuses[index] = true;
        return newStatuses;
      });
      setIsValid(true);
    } catch (error) {
      console.error("Error uploading zip file:", error);
    }
    // eslint-disable-next-line
  }, []);

  const checklistButtonClick = useCallback((item, idx) => {
    setChecklistActiveButton(idx);
    setIsValid(true);
    setParameters((prevParameters) => {
      const newParameters = [...prevParameters];
      newParameters[index] = {
        label: param.label,
        type: param.type,
        content: item,
      };
      return newParameters;
    });
    setParamStatuses((prevStatuses) => {
      const newStatuses = [...prevStatuses];
      newStatuses[index] = true;
      return newStatuses;
    });
    console.log(parametersRef.current);
    //eslint-disable-next-line
  }, []);

  const toggleItem = (item, index, idx) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((i) => i !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const toggleAllItems = (fields) => {
    const allSelected = fields.every((field) => selectedItems.includes(field));

    if (allSelected) {
      setSelectedItems(selectedItems.filter((item) => !fields.includes(item)));
    } else {
      setSelectedItems([...new Set([...selectedItems, ...fields])]);
    }
  };

  const handleTextChange = (index, event) => {
    const newText = event.target.value;

    setParameters((prevParameters) => {
      const newParameters = [...prevParameters];
      newParameters[index] = { type: "string", content: newText };
      return newParameters;
    });

    setParamStatuses((prevStatuses) => {
      const newStatuses = [...prevStatuses];
      newStatuses[index] = newText !== "";
      return newStatuses;
    });
  };

  const handlePlurialTextChange = (idx, key, event) => {
    const newTextInputs = [...textInputs];
    newTextInputs[idx][key] = event.target.value;
    setTextInputs(newTextInputs);

    setParameters((prevParameters) => {
      const newParameters = [...prevParameters];
      newParameters[index] = {
        type: "plurial-text-inputs",
        content: newTextInputs,
      };
      return newParameters;
    });

    setParamStatuses((prevStatuses) => {
      const newStatuses = [...prevStatuses];
      newStatuses[index] = newTextInputs.some(
        (input) => input.requirementName !== "" && input.prompt !== ""
      );
      return newStatuses;
    });
  };

  const addTextInput = () => {
    setTextInputs([
      ...textInputs,
      { requirementName: "", prompt: "", default: false, disabled: false },
    ]);
  };

  const handleDuplicate = (idx) => {
    setTextInputs((prevTextInputs) => {
      const newTextInputs = [
        ...prevTextInputs.slice(0, idx + 1),
        { ...prevTextInputs[idx], default: false, disabled: false },
        ...prevTextInputs.slice(idx + 1)
      ];
  
      // Mise à jour des paramètres
      setParameters((prevParameters) => {
        const newParameters = [...prevParameters];
        newParameters[index] = {
          type: "plurial-text-inputs",
          content: newTextInputs,
        };
        return newParameters;
      });
  
      return newTextInputs;
    });
  };
  
  const handleToggleSwitch = (idx, textInput) => {
    setCurrentToggleIdx(idx);
    setToggleDirection(!textInput.default);
    setInitialToggleState(textInput.default);
    setIsToggleModalOpen(true);
  };

  const confirmToggle = async () => {
    if (currentToggleIdx !== null) {
      const newTextInputs = [...textInputs];
      newTextInputs[currentToggleIdx].default = toggleDirection;
      setTextInputs(newTextInputs);

      setParameters((prevParameters) => {
        const newParameters = [...prevParameters];
        newParameters[index] = {
          type: "plurial-text-inputs",
          content: newTextInputs,
        };
        return newParameters;
      });

      const obj = {
        ...newTextInputs[currentToggleIdx],
        default: true,
      };

      const projectId = template.id;

      const requestData = {
        projectId,
        index,
        obj,
      };

      try {
        const endpoint = toggleDirection
          ? "/protected/project/addValue"
          : "/protected/project/deleteValue";
        const response = await Request.Post(endpoint, requestData);
        console.log("Backend response:", response);
      } catch (error) {
        console.error("Error making backend request:", error);
      }

      setCurrentToggleIdx(null);
      setIsToggleModalOpen(false);
    }
  };

  const cancelToggle = () => {
    if (currentToggleIdx !== null) {
      const newTextInputs = [...textInputs];
      newTextInputs[currentToggleIdx].default = initialToggleState;
      setTextInputs(newTextInputs);
    }

    setCurrentToggleIdx(null);
    setIsToggleModalOpen(false);
  };

  const openDeleteModal = (idx) => {
    setDeleteIdx(idx);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteIdx(null);
    setIsDeleteModalOpen(false);
  };

  const handleDelete = () => {
    if (deleteIdx !== null) {
      const newTextInputs = textInputs.filter((_, idx) => idx !== deleteIdx);
      setTextInputs(newTextInputs);

      setParameters((prevParameters) => {
        const newParameters = [...prevParameters];
        newParameters[index] = {
          type: "plurial-text-inputs",
          content: newTextInputs,
        };
        return newParameters;
      });

      setDeleteIdx(null);
      setIsDeleteModalOpen(false);
    }
  };

  const handleMoveUp = (idx) => {
    if (idx > 0) {
      setTextInputs((prevTextInputs) => {
        const newTextInputs = [...prevTextInputs];
        [newTextInputs[idx - 1], newTextInputs[idx]] = [
          newTextInputs[idx],
          newTextInputs[idx - 1],
        ];
  
        // Mise à jour des paramètres
        setParameters((prevParameters) => {
          const newParameters = [...prevParameters];
          newParameters[index] = {
            type: "plurial-text-inputs",
            content: newTextInputs,
          };
          return newParameters;
        });
  
        return newTextInputs;
      });
    }
  };
  
  const handleMoveDown = (idx) => {
    if (idx < textInputs.length - 1) {
      setTextInputs((prevTextInputs) => {
        const newTextInputs = [...prevTextInputs];
        [newTextInputs[idx], newTextInputs[idx + 1]] = [
          newTextInputs[idx + 1],
          newTextInputs[idx],
        ];
  
        // Mise à jour des paramètres
        setParameters((prevParameters) => {
          const newParameters = [...prevParameters];
          newParameters[index] = {
            type: "plurial-text-inputs",
            content: newTextInputs,
          };
          return newParameters;
        });
  
        return newTextInputs;
      });
    }
  };
  

  return (
    <div
      className={`param-container ${
        param.type.includes("checklist") ? "double no-click" : "single click"
      }`}
    >
      <h3>{param.label}</h3>
      {param.type.includes("checklist") ? (
        <Checklist
          param={param}
          checklistActiveButton={checklistActiveButton}
          checklistButtonClick={checklistButtonClick}
          selectedItems={selectedItems}
          toggleItem={toggleItem}
          toggleAllItems={toggleAllItems}
        />
      ) : param.type === "zip" || param.type === "pdf" ? (
        <FileUpload
          param={{ ...param, fileName, fileSize }}
          isValid={isValid}
          isUploading={isUploading}
          onFileChange={onFileChange}
          inputFileRef={inputFileRef}
          uploadProgress={uploadProgress}
        />
      ) : param.type === "string" ? (
        <div className="textbox">
          <input
            type="text"
            value={param.content}
            disabled={false}
            placeholder={param.value}
            onChange={(event) => handleTextChange(index, event)}
          />
        </div>
      ) : param.type === "plurial-text-inputs" ? (
        <div className="plurial-text-inputs">
          {textInputs.map((textInput, idx) => (
            <PlurialTextInput
              key={idx}
              textInput={textInput}
              idx={idx}
              handlePlurialTextChange={handlePlurialTextChange}
              handleDuplicate={handleDuplicate}
              handleToggleSwitch={handleToggleSwitch}
              openDeleteModal={openDeleteModal}
              handleMoveUp={handleMoveUp}
              handleMoveDown={handleMoveDown}
            />
          ))}
          <div className="addTextInput" onClick={addTextInput}>
            +
          </div>
          <ConfirmationModal
            open={isDeleteModalOpen}
            title="Êtes-vous sûr de vouloir supprimer la norme ?"
            text=""
            onConfirm={handleDelete}
            onCancel={closeDeleteModal}
          />
          <ConfirmationModal
            open={isToggleModalOpen}
            title={toggleDirection ? "Toggle :" : "Untoggle :"}
            text={
              toggleDirection
                ? "Voulez-vous enregistrer cette règle par défaut ? Vos collaborateurs l'auront en utilisant la fonction."
                : "Êtes-vous sûr de vouloir désélectionner la règle ? Si c'est le cas, elle ne sera plus présente par défaut lors des prochaines utilisations."
            }
            onConfirm={confirmToggle}
            onCancel={cancelToggle}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Parameters;
