import React, { useState } from 'react';
import Cookies from 'js-cookie';

const renderFolder = (Request, folder, level = 0, openFolders, setOpenFolders, currentPath = '', apiUrl, keyPrefix = '') => {
    const isOpen = openFolders.includes(folder.name);

    const toggleFolder = () => {
      if (isOpen) {
        setOpenFolders(prev => prev.filter(name => name !== folder.name));
      } else {
        setOpenFolders(prev => [...prev, folder.name]);
      }
    };

    const handleFileClick = async (relativePath) => {
      try {
        const response = await Request.Get(relativePath);
        console.log('response:', response);
        const fileUrl = response;
        window.open(`${fileUrl}`, '_blank');
      } catch (error) {
        console.error("Erreur lors de la récupération du fichier:", error);
      }
    };

    // Ajout du chemin actuel au chemin courant pour construire l'URL
    const newPath = `${currentPath}/${folder.name}`;

    return (
      <div key={keyPrefix + folder.name}>
          <div onClick={toggleFolder} style={{ marginLeft: `${level * 20}px`, cursor: 'pointer' }}>
          {folder.type === 'directory' ? (
              <>
              {isOpen ? '[-]' : '[+]'} {folder.name}
              </>
          ) : (
              <span onClick={() => handleFileClick(folder.link)} className='link'>{folder.name}</span>
          )}
          </div>
        {isOpen &&
          folder.children &&
          folder.children.map((subFolder, index) =>
            renderFolder(Request, subFolder, level + 1, openFolders, setOpenFolders, newPath, apiUrl, keyPrefix + folder.name + '-')
          )}
      </div>
    );
};

const FolderStructure = ({Request, folderStruct }) => {
  const [openFolders, setOpenFolders] = useState([]);
  // const apiUrl = localStorage.getItem("apiUrl");
  const apiUrl = Cookies.get("apiUrl");

  return (
      <div className='dossier'>
          <h3>Structure du Dossier</h3>
          <div className='folder'>
              {renderFolder(Request, folderStruct, 0, openFolders, setOpenFolders, '', `${apiUrl}`)}
          </div>
      </div>
  );
};

export default FolderStructure;


