import { DuplicateIcon, DeleteIcon, UpIcon, DownIcon } from "./icons";
import Switch from "@mui/material/Switch";

const PlurialTextInput = ({
  textInput,
  idx,
  handlePlurialTextChange,
  handleDuplicate,
  handleToggleSwitch,
  openDeleteModal,
  handleMoveUp,
  handleMoveDown,
}) => (
  <div className="plurial-text-inputs-component">
    <div className="inputs">
      <div className="input-actions">
        <input
          type="text"
          value={textInput.requirementName}
          placeholder="Nom de l'exigence"
          onChange={(event) =>
            handlePlurialTextChange(idx, "requirementName", event)
          }
          disabled={textInput.default}
        />
        <div className="action-buttons">
          <div
            className="action"
            title="Monter norme"
            onClick={() => handleMoveUp(idx)}
          >
            <UpIcon />
          </div>
          <div
            className="action"
            title="Dupliquer norme"
            onClick={() => handleMoveDown(idx)}
          >
            <DownIcon />
          </div>
          <div
            className="action duplicate"
            title="Dupliquer norme"
            onClick={() => handleDuplicate(idx)}
          >
            <DuplicateIcon />
          </div>
          <div title="Enregistrer la norme par défaut">
            <Switch
              checked={textInput.default}
              className="switch"
              onChange={() => handleToggleSwitch(idx, textInput)}
            />
          </div>
          <div
            className="action delete"
            title="Supprimer norme"
            onClick={() => openDeleteModal(idx)}
          >
            <DeleteIcon />
          </div>
        </div>
      </div>
      <textarea
        type="text"
        value={textInput.prompt}
        placeholder="Prompt"
        onChange={(event) => handlePlurialTextChange(idx, "prompt", event)}
        disabled={textInput.default}
      />
    </div>
  </div>
);

export default PlurialTextInput;
